import axios from "axios";
import { genSignature } from "@/plugins/gen";

const state = () => ({
  items: [],
  itemsId: [],
  textt: "test",
});

const mutations = {
  SET_SELECTED_ITEM(state, item) {
    state.items = item;
  },
  SET_SELECTED_ITEM_BY_ID(state, item) {
    state.itemsId = item;
  },
};

const actions = {
  async Promo({ commit }) {
    try {
      const mySignature = await genSignature("/api/v1/website/promotion/view");
      const resp = await axios.get(`/api/website/promotion/view`, {
        headers: {
          "X-SIGNATURE-APP": mySignature,
        },
      });
      commit("SET_SELECTED_ITEM", resp.data.resultData);
      return resp.data.resultData;
    } catch (error) {
      console.log(error);
      return error;
    }
    // genSignature("/api/v1/website/promotion/view").then((mySignature) => {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/api/website/promotion/view`, {
    //         headers: {
    //           "X-SIGNATURE-APP": mySignature,
    //         },
    //       })
    //       .then((resp) => {
    //         myresp = resp.data.resultData;
    //         commit("SET_SELECTED_ITEM", resp.data.resultData);

    //         resolve(resp);
    //       })
    //       .catch((err) => {
    //         reject(err);
    //       });
    //   });
    // });
  },
  PromoId({ commit }) {
    const id = localStorage.getItem("promotion_id");
    if (id) {
      genSignature(`/api/v1/website/promotion/view?id=${id}`).then(
        (mySignature) => {
          return new Promise((resolve, reject) => {
            axios
              .get(`/api/website/promotion/view?id=${id}`, {
                headers: {
                  "X-SIGNATURE-APP": mySignature,
                },
              })
              .then((resp) => {
                commit("SET_SELECTED_ITEM_BY_ID", resp.data.resultData);
                resolve(resp);
              })
              .catch((err) => {
                reject(err);
              });
          });
        }
      );
    } else {
      console.error("No promotion_id found in local storage");
    }
  },
};

const getters = {
  items: (state) => state.items,
  itemsId: (state) => state.itemsId,
  textt: (state) => state.textt,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
