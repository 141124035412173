import axios from "axios";
import { genSignature } from "@/plugins/gen";

const state = () => ({
  items: [],
  dialog: false,
  dialogTitle: "",
  dialogMessage: "",
  dialogDetail: null,
  loading: false,
});

const mutations = {
  SET_SELECTED_ITEM(state, items) {
    state.items = items;
  },
  SET_DIALOG(state, { dialog, dialogTitle, dialogMessage, dialogDetail }) {
    state.dialog = dialog;
    state.dialogTitle = dialogTitle;
    state.dialogMessage = dialogMessage;
    state.dialogDetail = dialogDetail;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  async postRequest({ commit }, { barcode }) {
    commit("SET_LOADING", true);
    try {
      const data = {
        lotteryTypeId: 2,
        barcode: barcode,
      };

      const signature = await genSignature({
        lotteryTypeId: 2,
        barcode: barcode,
      });
      const response = await axios.post("/api/website/laolot/WinPrize", data, {
        headers: {
          "X-SIGNATURE-APP": signature,
        },
      });

      if (response.data.status === 200 && response.data.error === false) {
        commit("SET_SELECTED_ITEM", response.data.resultData);
        commit("SET_DIALOG", {
          dialog: true,
          dialogTitle: response.data.title,
          dialogMessage: response.data.message,
          dialogDetail: response.data.resultData,
        });
      } else {
        commit("SET_DIALOG", {
          dialog: true,
          dialogTitle: response.data.title,
          dialogMessage: response.data.message,
          dialogDetail: response.data.resultData,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      commit("SET_DIALOG", {
        dialog: true,
        dialogTitle: "Error",
        dialogMessage: "ກະລຸນາໃສ່ເລກບິນ",
        dialogDetail: null,
      });
    } finally {
      commit("SET_LOADING", false);
    }
  },
  
};

const getters = {
  items: (state) => state.items,
  loading: (state) => state.loading,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
