// import axios from "axios";
// import { genSignature } from "@/plugins/gen";
const state = () => ({
  mobileDevice:
    typeof window !== "undefined" && window.innerWidth
      ? window?.innerWidth <= 600
        ? true
        : false
      : false,
  // Hello: "testv file",
});

const mutations = {
  SSET_IS_MOBILE_DEVICE(state) {
    state.mobileDevice =
      typeof window !== "undefined" && window.innerWidth
        ? window?.innerWidth < 600
          ? true
          : false
        : false;
  },
};

const actions = {};

const getters = {
  mobileDevice: (state) => state.mobileDevice,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
