import axios from "axios";
import { genSignature } from "@/plugins/gen";

const state = () => ({
  items6: [],
  items12: [],
  textt: "test",
});

const mutations = {
  SET_SELECTED_ITEM1(state, item6) {
    state.items6 = item6;
  },
  SET_SELECTED_ITEM2(state, item12) {
    state.items12 = item12;
  },
};

const actions = {
  async GPluzzle6({ commit }) {
    try {
      const mySignature6 = await genSignature("/api/v1/website/gamepuzzle/view?lotteryTypeId=1");
      const response = await axios.get(`/api/website/gamepuzzle/view?lotteryTypeId=1`, {
        headers: {
          "X-SIGNATURE-APP": mySignature6,
        },
      });
      commit("SET_SELECTED_ITEM1", response.data.resultData);
    } catch (error) {
      console.error("Error fetching puzzle 6:", error);
    }
  },
  async GPluzzle12({ commit }) {
    try {
      const mySignature12 = await genSignature("/api/v1/website/gamepuzzle/view?lotteryTypeId=2");
      const response12 = await axios.get(`/api/website/gamepuzzle/view?lotteryTypeId=2`, {
        headers: {
          "X-SIGNATURE-APP": mySignature12,
        },
      });
      commit("SET_SELECTED_ITEM2", response12.data.resultData);
    } catch (error) {
      console.error("Error fetching puzzle 12:", error);
    }
  },
};

const getters = {
  items6: (state) => state.items6,
  items12: (state) => state.items12,
  textt: (state) => state.textt,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
