import axios from "axios";
import { genSignature } from "@/plugins/gen";
const state = () => ({
  items: [],
  textt: "test",
});

const mutations = {
  SET_SELECTED_ITEM(state, item) {
    state.items = item;
  },
};

const actions = {
  LuckyP({ commit }) {
    let mySignature;
    genSignature("/api/v1/website/luckyperson/view").then((res) => {
      mySignature = res;
      // Signature------------------------------------------------------------------------------------------------------------>
      // console.log("downLoader Sg: ", mySignature);
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/website/luckyperson/view`, {
            headers: {
              "X-SIGNATURE-APP": mySignature,
            },
          })
          .then((resp) => {
            // respon Api--------------------------------------------------------------------------------------------------->
            // console.log("resp", resp);
            commit("SET_SELECTED_ITEM", resp.data.resultData);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  },
};

const getters = {
  items: (state) => state.items,
  roundSix: (state) => state.roundSix,
  textt: (state) => state.textt,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
