import axios from "axios";
import { genSignature } from "@/plugins/gen";
const state = () => ({
  result6: [],
  textt: "test",
});


const mutations = {
  SET_SELECTED_ITEM(state, item) {
    state.result6 = item;
  },
};

const actions = {
  MainResult({ commit }) {
    let mySignature;
    genSignature("/api/v1/website/laolot/WinPrizeHistory?type=1").then(
      (res) => {
        mySignature = res;
        // Signature------------------------------------------------------------------------------------------------------------>
        // console.log("main_result Sg: ", mySignature);
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/website/laolot/WinPrizeHistory?type=1`, {
              headers: {
                "X-SIGNATURE-APP": mySignature,
              },
            })
            .then((resp) => {
              // respon Api--------------------------------------------------------------------------------------------------->
              // console.log("resp", resp);
              commit("SET_SELECTED_ITEM", resp.data.resultData);
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    );
  },
  async fetchBannerData() {
    try {
      const mySignature = await genSignature(
        "/api/v1/website/laolot/WinPrizeHistory?type=1"
      );
      let response = await axios.get(
        "/api/website/laolot/WinPrizeHistory?type=1",
        {
          headers: {
            "X-SIGNATURE-APP": mySignature,
          },
        }
      );
      this.result6 = response.data.resultData;
    } catch (error) {
      console.error("Error!", error);
    }
  },
};

const getters = {
  result6: (state) => state.result6,
  roundSix: (state) => state.roundSix,
  textt: (state) => state.textt,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
