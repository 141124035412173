<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      class="hidden-lg-and-up"
      color="#9E1C20"
    >
      <v-list dense>
        <v-list-item-group>
          <div class="text-center">
            <img
              src="../assets/INSEE_HUB_LOGO.svg"
              alt="icon"
              style="height: 50px"
              class="my-2"
            />
          </div>
          <v-divider />
          <v-list-item
            v-for="(item, index) in menus"
            :key="index"
            :class="{ 'active-link-drawer': isActive(item.route) }"
          >
            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  :to="item.route"
                  class="white--text"
                  style="display: flex; text-decoration: none"
                >
                  <v-btn
                    text
                    class="rounded-0"
                    style="height: 100%; color: white"
                  >
                    <v-icon>{{ item.icon }}</v-icon> &nbsp;{{ item.title }}
                  </v-btn>
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="#9E1C20">
      <v-app-bar-nav-icon
        @click="toggleDrawer"
        class="hidden-lg-and-up"
        color="#FFC308"
      ></v-app-bar-nav-icon>
      <a href="/"
        ><img src="../assets/INSEE_HUB_LOGO.svg" style="height: 35px"
      /></a>

      <v-toolbar-items class="hidden-md-and-down ml-10">
        <router-link to="/" class="white--text">
          <v-btn
            dark
            text
            :class="{ 'active-link': isActive('/') }"
            class="rounded-0"
            style="height: 100%"
          >
            ໜ້າຫຼັກ
          </v-btn>
        </router-link>
        <router-link to="/result" class="white--text">
          <v-btn
            dark
            text
            :class="{ 'active-link': isActive('/result') }"
            class="rounded-0"
            style="height: 100%"
          >
            ຜົນອອກເລກ
          </v-btn>
        </router-link>
        <router-link to="/gamepluzzle" class="white--text">
          <v-btn
            dark
            text
            :class="{
              'active-link': isActive('/gamepluzzle') || isActiveGpluzzle(),
            }"
            class="rounded-0"
            style="height: 100%"
          >
            ບັນຫາພາລວຍ
          </v-btn>
        </router-link>
        <router-link to="/condition" class="white--text">
          <v-btn
            dark
            text
            :class="{ 'active-link': isActive('/condition') }"
            class="rounded-0"
            style="height: 100%"
          >
            ເງື່ອນໄຂຮັບລາງວັນ
          </v-btn>
        </router-link>
        <router-link to="/news" class="white--text">
          <v-btn
            dark
            text
            :class="{ 'active-link': isActive('/news') || isActiveNews() }"
            class="rounded-0"
            style="height: 100%"
          >
            ຂ່າວສານ
          </v-btn>
        </router-link>
        <router-link to="/luckyperson" class="white--text">
          <v-btn
            dark
            text
            :class="{ 'active-link': isActive('/luckyperson') }"
            class="rounded-0"
            style="height: 100%"
          >
            ຮູບພາບຜູ້ໂຊກດີ
          </v-btn>
        </router-link>

        <router-link to="/contact" class="white--text">
          <v-btn
            dark
            text
            :class="{ 'active-link': isActive('/contact') }"
            class="rounded-0"
            style="height: 100%"
          >
            ຕິດຕໍ່ເຮົາ
          </v-btn>
        </router-link>
      </v-toolbar-items>

      <div
        class="hidden-md-and-down"
        style="width: 100%; display: flex; justify-content: end"
      >
        <v-btn style="color: #86181c" @click="dialog = true">
          <v-icon>mdi-download-circle-outline</v-icon>
          ດາວໂຫລດແອັບ
        </v-btn>

        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <div style="display: flex; justify-content: end">
              <v-btn color="grey darken-1" text @click="dialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
            <v-card-title
              class="headline"
              style="display: flex; justify-content: center"
            >
              <strong>ສາມາດດາວໂຫລດ INSEE HUB ໄດ້ແລ້ວທີ່</strong>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <td v-for="(item, index) in items" class="px-3 pt-5">
                <qrcode-vue size="200" :value="item.link_url"></qrcode-vue>
                <a :href="item.link_url"
                  ><img :src="img[index]" style="width: 100%; margin-top: 25px"
                /></a>
              </td>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      drawer: false,
      img: [
        require("../assets/Play store.png"),
        require("../assets/App store.png"),
      ],
      menus: [
        { title: "ໜ້າຫຼັກ", route: "/", icon: "mdi-home-outline" },
        { title: "ຜົນອອກເລກ", route: "/result", icon: "mdi-list-box-outline" },
        { title: "ບັນຫາພາລວຍ", route: "/gamepluzzle", icon: "mdi-bed-clock" },
        {
          title: "ເງື່ອນໄຂຮັບລາງວັນ",
          route: "/condition",
          icon: "mdi-book-open-variant-outline",
        },
        {
          title: "ຂ່າວສານ",
          route: "/news",
          icon: "mdi-newspaper-variant-outline",
        },
        {
          title: "ຮູບພາບຜູ້ໂຊກດີ",
          route: "/luckyperson",
          icon: "mdi-image-auto-adjust",
        },
        { title: "ຕິດຕໍ່ເຮົາ", route: "/contact", icon: "mdi-phone-outline" },
      ],
    };
  },
  computed: {
    ...mapGetters("downLoader", ["items"]),
  },
  components: {
    QrcodeVue,
  },
  methods: {
    ...mapActions("downLoader", ["Downl"]),

    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    isActive(path) {
      return this.$route.path === path;
    },
    isActiveNews() {
      const newsInfoPath = `/newsInfo/${this.$route.params.id}`;
      return this.$route.path === newsInfoPath || this.$route.path === "/news";
    },
    isActiveGpluzzle() {
      const GpluzzleInfoPath = `/gamepluzzle/info/${this.$route.params.id}`;
      return (
        this.$route.path === GpluzzleInfoPath ||
        this.$route.path === "/gamepluzzle"
      );
    },
  },
  async mounted() {
    await this.Downl();
  },
};
</script>

<style scoped>
.white--text {
  color: white !important;
}
.black--text {
  color: black !important;
}
.active-link {
  background-color: #660b0e;
  border-bottom: 5px solid #ffc308;
}
.active-link-drawer {
  background-color: #86181c;
}

.active-link-drawer >>> .v-list-item__content,
.active-link-drawer >>> .v-list-item__title,
.active-link-drawer >>> .v-btn {
  color: #ffc308 !important; /* Change this to the desired text color */
}

* {
  -webkit-user-drag: none;
}
</style>
