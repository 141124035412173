import Vue from "vue";
import VueRouter from "vue-router";
// import from '../views/.vue'

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component:
  // }
  {
    path: "/",
    name: "home",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/result",
    name: "result",
    component: () => import("../views/result.vue"),
  },
  {
    path: "/gamepluzzle",
    name: "gamepluzzle",
    component: () => import("../views/gamepluzzle.vue"),
  },
  {
    path: "/gamepluzzle/info/:id",
    name: "gamepluzzleinfo",
    component: () => import("../views/gamepluzzleInfo.vue"),
  },
  {
    path: "/condition",
    name: "condition",
    component: () => import("../views/condition.vue"),
  },
  {
    path: "/dealer",
    name: "dealer",
    component: () => import("../views/dealer.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news.vue"),
  },
  {
    path: "/newsInfo/:id",
    name: "newsInfo",
    component: () => import("../views/newsInfo.vue"),
    props: true,
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue"),
  },
  {
    path: "/luckyperson",
    name: "luckyperson",
    component: () => import("../views/luckyperson.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../components/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
