// store/modules/result.js
import axios from "axios";
const state = () => ({
  items: [],
  roundSix: null,
  textt: "test",
});





const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_SELECTED_ITEM(state, item) {
    state.roundSix = item;
  },
};




const actions = {

  GetRound({commit}) {
    return new Promise((resolve, reject) => {
      const res = axios
        .get(`/lot/StandardData/list_roundAsync`)
        .then((resp) => {
          commit("SET_SELECTED_ITEM", resp.data.model);
            console.log("res", resp.data.model);

          resolve(resp);
        })
        .catch((err) => {
          // commit('StateError', err)
          reject(err);
        });
    });

  },
};

const getters = {
  items: (state) => state.items,
  roundSix: (state) => state.roundSix,
  textt: (state) => state.textt,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
